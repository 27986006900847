import {
  CampaignMember,
  CampaignMemberStatus,
  useApproveManyMembersMutation,
  useUpdateMemberClaimedMutation,
} from '@app/services';
import { CopyIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Flex,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  AddReceiverModal,
  MembersTable,
  NotchedButton,
  StatusBadge,
} from '@components';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import {
  Event,
  getCampaignById,
  selectEventName,
  setEvent,
  setIdle,
} from '@features';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  addManyWhitelister,
  addWhitelister,
  claimLixiByOwnerOrAdmin,
  fromWei,
  getMemberStatusByLabel,
  getMemberStatusProps,
  isHex,
  isMetamaskError,
  removeWhitelister,
} from '@utils';
import { useWeb3React } from '@web3-react/core';
import {
  formatAddress,
  sendGasToMany,
  useSetWhitelisterListener,
} from '@web3react';
import copyToClipboard from 'copy-to-clipboard';
import { round } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Column } from 'react-table';
import {
  NumberRangeColumnFilter,
  SelectColumnFilter,
  SelectEmailFilter,
} from './_filterComponents';

export interface MembersTableContainerProps {
  isOwner?: boolean;
  campaignId: string;
  isAdmin?: boolean;
  signer: JsonRpcSigner | undefined;
}

export const MembersTableContainer: React.FC<MembersTableContainerProps> = ({
  isOwner,
  campaignId,
  isAdmin,
  signer,
}) => {
  const [isSelectAllUsers, setIsSelectAllUsers] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const campaign = useAppSelector(state => getCampaignById(state, campaignId));

  const [updateMemberClaimed] = useUpdateMemberClaimedMutation();
  const [approveManyUsers] = useApproveManyMembersMutation();

  const dispatch = useAppDispatch();
  const eventName = useAppSelector(selectEventName);

  useSetWhitelisterListener();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'bottom',
  });

  const { library } = useWeb3React<Web3Provider>();

  const handleSelectAllUsers = (isTrue: boolean) => {
    if (isTrue) {
      const allPendingUsers = campaign?.members || ([] as CampaignMember[]);
      const allPendingUserIds = allPendingUsers.map(mb => mb.userId);
      setSelectedUserIds(allPendingUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const getWalletAddresses = () => {
    if (!campaign || !campaign.members) {
      return [];
    }

    const selectedMembers =
      campaign.members.filter(mb => selectedUserIds.includes(mb.userId)) || [];
    return selectedMembers.map(mb => mb.user.walletAddress);
  };

  const handleAddWhitelister = async (
    userId: string,
    groupId?: number,
    userAddress?: string,
  ) => {
    if (!library) return;

    // Call Lixibag sm
    try {
      dispatch(
        setEvent({
          eventName: isAdmin
            ? Event.SETWHITELISTER_BY_ADMIN_WAITING
            : Event.SETWHITELISTER_WAITING,
          payload: { campaignId, userId },
        }),
      );

      console.log(`Call ${addWhitelister.name} with arguments`);
      console.log({
        groupId,
        userAddress,
        signer,
        isAdmin,
      });
      await addWhitelister(groupId, userAddress, library.getSigner(0), isAdmin);
    } catch (err) {
      dispatch(setIdle());
      console.log(err);

      let message = 'Kết nối tới ví của bạn bị lỗi. Làm ơn thử lại.';

      if (err instanceof Error) {
        message = err.message;
      }

      if (isMetamaskError(err)) {
        message = err.data.message;
      }

      toast({
        title: 'Error',
        description: message,
        status: 'error',
      });
    }
  };

  const handleRemoveWhitelister = async (
    groupId?: number,
    userAddress?: string,
  ) => {
    try {
      await removeWhitelister(groupId, userAddress, signer);
      toast({
        title: 'Bạn đã huỷ thành công',
        status: 'success',
      });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Thông báo lỗi',
        description: 'Thao tác huỷ lỗi. Bạn thử lại sau.',
        status: 'error',
      });
    }
  };

  const handleClaimLixiByOwner = async (
    userId: string,
    groupId?: number,
    userAddress?: string,
  ) => {
    try {
      await claimLixiByOwnerOrAdmin(groupId, userAddress, signer, isAdmin);

      await updateMemberClaimed({ campaignId, userId }).unwrap();

      toast({
        title: `Bạn đã gửi thành công lì xì tới ${userAddress}`,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Gửi lì xì thất bạn. Vui lòng thử lại sau',
        status: 'error',
      });
    }
  };

  const ownerActions = (
    status: string,
    userId: string,
    groupId: number | undefined,
    userAddress: string | undefined,
  ) => {
    if (status === 'PENDING') {
      return (
        <Flex>
          <NotchedButton
            variant="outline"
            color="primary"
            borderColor="primary"
            isLoading={
              eventName === Event.SETWHITELISTER_BY_ADMIN_WAITING ||
              eventName === Event.SETWHITELISTER_WAITING
            }
            onClick={() => handleAddWhitelister(userId, groupId, userAddress)}
          >
            Duyệt
          </NotchedButton>
        </Flex>
      );
    } else if (status === 'APPROVED') {
      return (
        <Flex gap={2} alignItems="center">
          <Flex alignItems="center" gap={1}>
            <Tooltip label="Bạn có thể huỷ yêu cầu nhận của người này">
              <InfoOutlineIcon />
            </Tooltip>
            <Text
              onClick={() => handleRemoveWhitelister(groupId, userAddress)}
              css={{ cursor: 'pointer' }}
            >
              Huỷ
            </Text>
          </Flex>
          <NotchedButton
            onClick={() => handleClaimLixiByOwner(userId, groupId, userAddress)}
            bgColor="primary"
            color="white"
          >
            Gửi lì xì
          </NotchedButton>
        </Flex>
      );
    }
  };

  const updateSelectedWallets = (selectedUserId: string) => {
    const idxElem = selectedUserIds.indexOf(selectedUserId);

    if (idxElem > -1) {
      setSelectedUserIds(
        selectedUserIds.filter(item => item !== selectedUserId),
      );
    } else {
      setSelectedUserIds([...selectedUserIds, selectedUserId]);
    }
  };

  const getInfoByStatus = (status: string) => {
    if (status === 'PENDING') {
      return 'Bạn cần duyệt yêu cầu này để chuyển lì xì cho người nhận';
    } else if (status === 'APPROVED') {
      return 'Nguời này đã được nhận thông báo để quay lại nhận lì xì';
    } else if (status === 'DECLINED') {
      return 'Người này đã bị từ chối gửi lì xì';
    } else if (status === 'CLAIMED') {
      return 'Người này đã nhận được lì xì';
    }
  };

  const handleSendGasToMany = async () => {
    const receivers = getWalletAddresses().filter(
      (addr): addr is string => !!addr && isHex(addr),
    );

    if (!library || !receivers.length) return;

    try {
      await sendGasToMany(receivers, library.getSigner(0));
      toast({
        title: 'Thành công',
        description: 'Gửi gas thành công',
        status: 'success',
      });
    } catch (error) {
      console.log(error);

      let message = 'Có lỗi xảy ra khi gửi gas';
      if (error instanceof Error) {
        message = error.message;
      }
      if (isMetamaskError(error)) {
        message = error.data.message;
      }

      toast({
        title: 'Thất bại',
        description: message,
        status: 'error',
      });
    }
  };

  const handleApproveManyUsers = async () => {
    try {
      await addManyWhitelister(
        campaign?.groupId,
        getWalletAddresses(),
        signer,
        isAdmin,
      );

      await approveManyUsers({
        userIds: selectedUserIds,
        campaignId: campaign?._id as string,
      }).unwrap();

      toast({
        title: `Bạn đã duyệt thành công cho các thành viên chọn`,
        status: 'success',
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      console.log(err);
      toast({
        title: `Duyệt thành viên thất bại`,
        status: 'error',
      });
    }
  };

  const getColumnsMembers = useMemo(() => {
    const commonColumns: Column<object>[] = [
      {
        Header: 'Trạng thái',
        accessor: 'status',
        id: 'status',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const label: string = props.cell.value;
          const memberStatus = getMemberStatusByLabel(label);

          return (
            <Flex alignItems="center" gap={1}>
              <Tag color={memberStatus.color} bgColor={memberStatus.bgColor}>
                {label}
              </Tag>
              <Tooltip label={getInfoByStatus(memberStatus.status)}>
                <InfoOutlineIcon />
              </Tooltip>
            </Flex>
          );
        },
      },
      {
        Header: 'Người nhận',
        accessor: 'receiver',
        id: 'receiver',
        filter: 'text',
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const fullName = props.cell.value;
          return (
            <Text fontSize={16} color="darkBg">
              {isOwner ? (
                fullName ? (
                  fullName
                ) : (
                  <Tag colorScheme="red">Không tên</Tag>
                )
              ) : (
                <Tag>Đã ẩn</Tag>
              )}
            </Text>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        id: 'email',
        filter: (rows, ids, filterValue) => {
          const id = ids[0];
          return rows.filter(row => {
            const email = row.values[id];
            const members = campaign?.members ?? [];
            const member =
              members.find(mb => mb.user.email === email) ??
              ({} as CampaignMember);

            const isEmailVerified = !!member?.user.isEmailVerified;
            if (filterValue === 'true') {
              return !!isEmailVerified === true;
            }
            if (filterValue === 'false') {
              return !!isEmailVerified === false;
            }

            return true;
          });
        },
        Filter: SelectEmailFilter,
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const email: string = props.cell.value;
          const members = campaign?.members ?? [];
          const member =
            members.find(mb => mb.user.email === email) ??
            ({} as CampaignMember);
          return (
            <Box fontSize={16} color="darkBg">
              {isOwner ? (
                <Flex alignItems="center" gap={1}>
                  <Text>
                    {email ? email : <Tag colorScheme="red">Không có</Tag>}
                  </Text>
                  {email &&
                    (member?.user.isEmailVerified ? (
                      <StatusBadge
                        tooltip="Tài khoản đã xác thực"
                        status="trust"
                      />
                    ) : (
                      <StatusBadge
                        tooltip="Tài khoản chưa xác thực"
                        status="warning"
                      />
                    ))}
                </Flex>
              ) : (
                <Tag>Đã ẩn</Tag>
              )}
            </Box>
          );
        },
      },
      {
        Header: 'Địa chỉ ví',
        accessor: 'walletAddress',
        id: 'walletAddress',
        filter: 'text',
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const walletAddress: string = props.cell.value;
          return (
            <Flex alignItems="center" gap={1}>
              <Text fontSize={16} color="darkBg">
                {formatAddress(walletAddress)}
              </Text>
              <CopyIcon
                cursor="pointer"
                onClick={() => {
                  copyToClipboard(walletAddress);
                  toast({
                    title: 'Đã sao chép',
                    position: 'top-right',
                  });
                }}
              />
            </Flex>
          );
        },
      },
      {
        Header: 'Số dư (BNB)',
        accessor: 'balance',
        id: 'balance',
        filter: (rows, ids, filterValue) => {
          const id = ids[0];
          let [min, max] = filterValue;
          if (typeof min === 'string') {
            min = parseFloat(min);
          }
          if (typeof max === 'string') {
            max = parseFloat(max);
          }

          return rows.filter(row => {
            let balance = row.values[id];
            if (typeof balance === 'string') {
              balance = parseFloat(balance);
            }

            if (Number.isNaN(min) && Number.isNaN(max)) {
              return true;
            }

            if (Number.isNaN(min)) {
              return balance <= max;
            }

            if (Number.isNaN(max)) {
              return balance >= min;
            }

            return min <= balance && balance <= max;
          });
        },
        Filter: NumberRangeColumnFilter,
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const balance = props.cell.value;
          return (
            <VStack>
              <Text fontSize={16} color="darkBg">
                {balance}
              </Text>
            </VStack>
          );
        },
      },
    ];

    const commonDisableFilterProps = {
      disableFilters: true,
      disableGlobalFilter: true,
    };

    if (isOwner) {
      commonColumns.unshift({
        Header: (
          <VStack>
            <Text minW="10">Chọn tất cả</Text>
            <Checkbox
              color="primary"
              isChecked={isSelectAllUsers}
              onChange={() => {
                setIsSelectAllUsers(!isSelectAllUsers);
                handleSelectAllUsers(!isSelectAllUsers);
              }}
            />
          </VStack>
        ),
        accessor: 'cbUsers',
        id: 'cbUsiers',
        ...commonDisableFilterProps,
      });

      commonColumns.push({
        Header: '',
        accessor: 'actions',
        id: 'actions',
        ...commonDisableFilterProps,
      });
    }

    return commonColumns;
  }, [isOwner, isSelectAllUsers]);

  const getMemberData = useMemo(() => {
    if (!campaign || !campaign.members) {
      return [];
    }
    return campaign.members.map(mb => {
      const status = mb.status.toString();
      const memberStatus = getMemberStatusProps(status);
      const memberUserId = mb.userId;
      const balance = round(
        parseFloat(fromWei(mb.user.balanceInWei || '0')),
        2,
      );

      return {
        cbUsers: (
          <Checkbox
            isChecked={
              memberUserId ? selectedUserIds.includes(memberUserId) : false
            }
            value={mb.userId}
            onChange={event => {
              updateSelectedWallets(event.target.value);
            }}
          />
        ),
        status: memberStatus.label,
        receiver: mb.user.fullName,
        email: mb.user.email,
        walletAddress: mb.user.walletAddress,
        balance,
        actions: ownerActions(
          status,
          mb.userId,
          campaign.groupId,
          mb.user.walletAddress,
        ),
      };
    });
  }, [campaign, selectedUserIds, isOwner, eventName]);

  const isDisableApproveManyBtn =
    selectedUserIds.length === 0 ||
    selectedUserIds.some(id => {
      const members = campaign?.members || ([] as CampaignMember[]);
      const member = members.find(mb => mb.userId === id);
      return (
        member &&
        [CampaignMemberStatus.APPROVED, CampaignMemberStatus.CLAIMED].includes(
          member.status,
        )
      );
    });

  return (
    <>
      <Flex w="100%">
        <MembersTable
          columns={getColumnsMembers}
          data={getMemberData}
          hasFilter={isOwner}
        />
      </Flex>
      {isOwner && (
        <Flex>
          <NotchedButton
            marginBottom="69px"
            bgColor="primary"
            color="white"
            w="20%"
            disabled={isDisableApproveManyBtn}
            onClick={handleApproveManyUsers}
          >
            Duyệt lựa chọn
          </NotchedButton>
          <NotchedButton
            marginLeft="10px"
            disabled={selectedUserIds.length === 0}
            onClick={handleSendGasToMany}
          >
            Tặng gas
          </NotchedButton>
          <NotchedButton marginLeft="10px" onClick={onOpen}>
            Thêm người nhận
          </NotchedButton>
          <AddReceiverModal
            campaignId={campaignId}
            isAdmin={isAdmin}
            isOpen={isOpen}
            onClose={onClose}
          />
        </Flex>
      )}
    </>
  );
};
