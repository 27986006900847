import { ExternalLinkIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { LeftHeader, WrapperPattern } from '@components';
import { BLOG_URL } from '@constants';
import { Web3Provider } from '@ethersproject/providers';
import {
  selectCurrentToken,
  selectCurrentUser,
  setToken,
  setUser,
} from '@features/auth/authSlice';
import { selectWallet, setWallet } from '@features/wallet/walletSlice';
import {
  useAppDispatch,
  useAppSelector,
  useAuth,
  useWindowDimensions,
} from '@hooks';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { getErrorMessage } from '@web3react';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RightHeaderContainer } from '../RightHeaderContainer';

interface HeaderContainerProps {
  handleWarningChain: () => void;
}

export const HeaderContainer: React.FC<HeaderContainerProps> = ({
  handleWarningChain,
}) => {
  const { error, library, chainId, account } = useWeb3React<Web3Provider>();

  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectCurrentToken);
  const user = useAppSelector(selectCurrentUser);
  const wallet = useAppSelector(selectWallet);

  const [isShow, setIsShow] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const { width } = useWindowDimensions();

  const { hasLogin } = useAuth();

  useEffect(() => {
    const widthEm = width / 16;
    if (widthEm > 48) {
      setIsShow(true);
      setShowButton(false);
    } else {
      setIsShow(false);
      setShowButton(true);
    }
  }, [width]);

  useEffect(() => {
    (async () => {
      if (wallet?.address && wallet.address !== account) {
        dispatch(setToken(null));
      }
    })();
  }, [account, chainId, library]);

  useEffect(() => {
    (() => {
      if (!error) return;

      if (error instanceof UnsupportedChainIdError) {
        return handleWarningChain();
      }

      const id = 'error toast';
      if (!toast.isActive(id)) {
        toast({
          id,
          title: error.name,
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    })();
  }, [error]);

  useEffect(() => {
    if (token) return;

    if (wallet) dispatch(setWallet(null));

    if (user) dispatch(setUser(null));
  }, [token]);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  const LinkGroup = () => (
    <HStack display={{ base: 'none', md: 'flex' }} mr="10" spacing="7">
      <Box marginY={{ base: '5px', md: '0' }}>
        <Link href={BLOG_URL} isExternal>
          <Text
            color="white"
            fontFamily="body"
            fontSize="16px"
            fontWeight="600"
          >
            Blog <ExternalLinkIcon />
          </Text>
        </Link>
      </Box>
      <Box marginY={{ base: '5px', md: '0' }}>
        <RouterLink to="/the-le">
          <Text
            color="white"
            fontFamily="body"
            fontSize="16px"
            fontWeight="600"
          >
            Thể lệ
          </Text>
        </RouterLink>
      </Box>
    </HStack>
  );

  return (
    <>
      <WrapperPattern
        justifyContent="space-between"
        alignItems="center"
        px={{ base: '5px', md: '20' }}
        paddingTop={{ base: '0', md: '1.25rem' }}
        paddingBottom={{ base: '0', md: '1.25rem' }}
        backgroundColor={{
          base: showButton && isShow ? 'white' : 'darkBg',
          md: hasScrolled ? 'darkBg' : 'transparent',
        }}
        borderBottom={{ base: '1px solid #E2E8F0', md: 'none' }}
        position="fixed"
        top="0"
        left="0"
        zIndex="10000"
        w="100vw"
      >
        <Box width={{ base: 'calc(100%/3)', md: 'auto' }}>
          <IconButton
            marginY={{ base: '10px', md: '0' }}
            display={showButton ? 'inline-flex' : 'none'}
            aria-label="Open Menu"
            size="lg"
            icon={<HamburgerIcon />}
            onClick={() => setIsShow(!isShow)}
          />
        </Box>
        <Box width={{ base: 'calc(100%/3)', md: 'auto' }}>
          <LeftHeader />
        </Box>

        <Spacer display={{ base: 'none', md: 'block' }} />

        <LinkGroup />

        <Box width={{ base: 'calc(100%/3)', md: 'auto' }}>
          <RightHeaderContainer showButton={showButton} />
        </Box>
      </WrapperPattern>
      <Box
        display={showButton && isShow ? 'block' : 'none'}
        background="white"
        paddingX="5px"
        position={{ base: 'fixed', md: 'static' }}
        top="4.3rem"
        left="0"
        right="0"
        zIndex="99999"
      >
        <Link href={BLOG_URL} isExternal>
          <Text
            color="darkBg"
            fontFamily="body"
            fontSize="16px"
            fontWeight="600"
            height="50px"
            lineHeight="50px"
          >
            Blog <ExternalLinkIcon />
          </Text>
        </Link>
        <RouterLink to="/the-le" onClick={() => setIsShow(false)}>
          <Text
            color="darkBg"
            fontFamily="body"
            fontSize="16px"
            fontWeight="600"
            height="50px"
            lineHeight="50px"
          >
            Thể lệ
          </Text>
        </RouterLink>
        {hasLogin ? (
          <>
            <RouterLink to="/lixi" onClick={() => setIsShow(false)}>
              <Text
                color="darkBg"
                fontFamily="body"
                fontSize="16px"
                fontWeight="600"
                height="50px"
                lineHeight="50px"
              >
                Danh sách lì xì
              </Text>
            </RouterLink>
            <RouterLink to="/my-lixi" onClick={() => setIsShow(false)}>
              <Text
                color="darkBg"
                fontFamily="body"
                fontSize="16px"
                fontWeight="600"
                height="50px"
                lineHeight="50px"
              >
                Lì xì của tôi
              </Text>
            </RouterLink>
            <RouterLink to="/profile" onClick={() => setIsShow(false)}>
              <Text
                color="darkBg"
                fontFamily="body"
                fontSize="16px"
                fontWeight="600"
                height="50px"
                lineHeight="50px"
              >
                Thông tin của bạn
              </Text>
            </RouterLink>
          </>
        ) : null}
      </Box>
    </>
  );
};
export default HeaderContainer;
