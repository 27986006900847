import { Web3Provider } from '@ethersproject/providers';
import {
  selectCurrentToken,
  selectCurrentUser,
  selectWallet,
  setToken,
} from '@features';
import { useAppDispatch, useAppSelector } from '@hooks';
import { signMessage } from '@utils';
import { useWeb3React } from '@web3-react/core';
import { connectorsByName } from '@web3react';
import { useEffect, useState } from 'react';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const token = useAppSelector(selectCurrentToken);

  const wallet = useAppSelector(selectWallet);

  const { library, activate, setError, deactivate, account, chainId } =
    useWeb3React<Web3Provider>();

  const [isSignInCall, setIsSignInCall] = useState(false);
  const hasConnectToWeb3React = account && chainId && library;
  const hasLogin = token && user && wallet;

  useEffect(() => {
    (async () => {
      if (!isSignInCall) return;

      try {
        if (!library) return;

        const signer = library.getSigner();
        const signedToken = await signMessage(signer);
        dispatch(setToken(signedToken));
      } catch (connectWalletError) {
        setError(connectWalletError as Error);
      } finally {
        setIsSignInCall(false);
      }
    })();
  }, [isSignInCall]);

  const signIn = async (): Promise<void> => {
    await activate(connectorsByName.Injected);
    setIsSignInCall(true);
  };

  const signOut = async (): Promise<void> => {
    window.localStorage.removeItem('wallet_signed_token');
    deactivate();
  };

  return {
    user,
    token,
    signIn,
    signOut,
    wallet,
    hasConnectToWeb3React,
    hasLogin,
  };
};
