import { BigNumber } from '@ethersproject/bignumber';
import { getLixiBagContract } from '@web3react';

export interface GroupInfo {
  groupSize: BigNumber;
  groupClaimed: BigNumber;
  groupHash: string;
  groupOwner: string;
  groupLocalJackpotToken: string;
  groupLocalJackpotTokenAmount: BigNumber;
  groupLocalJackpotPrize: BigNumber;
  groupStartTicketId: BigNumber;
  groupLocalJackpotWinner: string;
}

export const getGroupInfo = async (groupId: number): Promise<GroupInfo> => {
  const contract = getLixiBagContract();
  const groupInfo = (await contract.groupInfo(groupId)) as GroupInfo;
  console.log(groupInfo);
  return groupInfo;
};
