import { useToast } from '@chakra-ui/react';
import { NotchedButton, RightHeader } from '@components';
import { Web3Provider } from '@ethersproject/providers';
import { formatEther } from '@ethersproject/units';
import { selectCurrentUser, setUser, setToken } from '@features/auth/authSlice';
import { selectWallet, setWallet } from '@features/wallet/walletSlice';
import { useAppDispatch, useAppSelector, useAuth } from '@hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetMeQuery, useSignUpWithoutInfoQuery } from '@services';
import { useWeb3React } from '@web3-react/core';
import { NETWORKS } from '@web3react';
import React, { useEffect } from 'react';

export interface RightHeaderContainerProps {
  showButton?: boolean;
}

export const RightHeaderContainer: React.FC<RightHeaderContainerProps> = ({
  showButton,
}) => {
  const { account, library, chainId } = useWeb3React<Web3Provider>();
  const dispatch = useAppDispatch();
  const toast = useToast({
    duration: 2000,
    isClosable: true,
    position: 'bottom',
  });

  const { signIn, signOut, hasLogin, token } = useAuth();

  // User logic
  const { error: getMeError, refetch } = useGetMeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { error: signupError, refetch: refetchSignUp } =
    useSignUpWithoutInfoQuery(
      getMeError?.message?.includes('Invalid token') ? undefined : skipToken,
    );

  const user = useAppSelector(selectCurrentUser);
  const tokenInRedux = useAppSelector(state => state.auth.token);

  useEffect(() => {
    (async () => {
      if (signupError) {
        const name = signupError.name || 'Error';
        const message = signupError.message || 'Something went wrong';

        return toast({
          title: name,
          description: message,
          status: 'error',
        });
      }

      if (getMeError?.message?.includes('Token expired')) {
        return signOut();
      }

      if (getMeError?.message?.includes('Invalid token')) {
        refetchSignUp();
      }
    })();
  }, [getMeError, signupError]);

  useEffect(() => {
    if (!user) refetch();

    const tkInLocalStorage = window.localStorage.getItem('wallet_signed_token');
    if (!tokenInRedux && tkInLocalStorage) {
      dispatch(setToken(tkInLocalStorage));
    }
  }, []);

  useEffect(() => {
    if (token && !user) {
      refetch();
      return;
    }
    if (!user || !user.walletAddress || !account) return;

    if (user.walletAddress.toLowerCase() !== account.toLowerCase())
      dispatch(setUser(null));

    // if (user.walletAddress.toLowerCase() !== account.toLowerCase()) {
    //   refetch();
    // }
  }, [token, user]);

  // Wallet logic
  const wallet = useAppSelector(selectWallet);
  useEffect(() => {
    (async () => {
      const isConnected = !!account && !!library && !!chainId;

      if (wallet && !isConnected) dispatch(setWallet(null));

      if (!isConnected) return;

      if (user && user.walletAddress?.toLowerCase() !== account.toLowerCase())
        return;

      const balanceBigNumber = await library.getBalance(account);
      const balance = Number.parseFloat(
        Number.parseFloat(formatEther(balanceBigNumber)).toFixed(5),
      );
      const network = NETWORKS[chainId].name;
      const symbol = NETWORKS[chainId].nativeCurrency.symbol;
      const currentWallet = {
        address: account,
        balance,
        network,
        symbol,
      };

      dispatch(setWallet(currentWallet));
    })();
  }, [chainId, account, library, user]);

  if (!hasLogin) {
    return (
      <NotchedButton
        float={showButton ? 'right' : 'none'}
        transparent={showButton}
        bgColor="primary"
        color="white"
        p={'.5rem 1rem'}
        borderRadius="4px!important"
        onClick={signIn}
        _hover={{ background: showButton ? 'transparent' : 'E2E8F0' }}
      >
        Kết nối ví
      </NotchedButton>
    );
  }

  return <RightHeader user={user} wallet={wallet} />;
};

export default RightHeaderContainer;
