import { User } from '@app/services/auth';
import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { StatusBadge } from '@components';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export interface AccountInfoProps {
  user: User | null;
}

export const AccountInfo: React.FC<AccountInfoProps> = ({ user = null }) => {
  const navigate = useNavigate();

  if (!user) return null;

  const icon = user.isEmailVerified ? (
    <StatusBadge tooltip="Email đã xác thực" status="trust" />
  ) : (
    <StatusBadge tooltip="Email chưa xác thực" status="warning" />
  );

  const Info: React.FC = props => (
    <Box {...props}>
      <Flex alignItems="center">
        <Box mr="2">
          <Text fontWeight="bold">{user.fullName || 'Anonymous'}</Text>
        </Box>
        {user.email && <Box>{icon}</Box>}
      </Flex>
      <Text fontSize="sm" fontStyle="italic">
        {user.email}
      </Text>
    </Box>
  );

  return (
    <Menu placement="auto">
      <MenuButton>
        <Flex alignItems="center">
          <Avatar src={user.avatar} size="sm" />
        </Flex>
      </MenuButton>
      <MenuList p="3">
        <Box>
          <Info />
        </Box>
        <MenuItem
          onClick={() => {
            navigate('/lixi');
          }}
        >
          Danh sách lì xì
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/my-lixi');
          }}
        >
          Lì xì của tôi
        </MenuItem>
        <MenuItem>
          <Link to="/profile">Thông tin của bạn</Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default AccountInfo;
